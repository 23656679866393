@charset 'UTF-8';

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100&display=swap");

// 1. Configuration and helpers

@import 
    'src/scss/abstracts/variables',
    'src/scss/abstracts/functions',
    'src/scss/abstracts/mixins';

// 2. Vendors



// 3. Base stuff

@import 
    'src/scss/base/base',
    'src/scss/base/animations',
    'src/scss/base/type',
    'src/scss/base/utils',
    'src/scss/base/grid';

// 4. Layout-related stuff



// 5. Components

@import
    'src/scss/components/button',
    'src/scss/components/navigation',
    'src/scss/components/popup';

// 6. Page-specific styles

@import
  'src/scss/pages/home';

// 7. Themes


