.heading-primary {

    font-weight: 400;
    color: #FFFFFF;
    opacity: .8;

    display: inline-block;
    font-size: 4.8rem;

    @media(min-width: 768px) {
        font-size: 7.2rem;
    }

}

.heading-secondary {

    font-size: 2.4rem;
    font-weight: 100;
    color: #FFFFFF;


    @media(min-width: 768px) {
        font-size: 4.5rem;
    }

}

.heading-tertiary {
    font-size: 2.2rem;
    font-weight: 1000;

    @media(min-width: 768px) {
        font-size: 2.4rem;
    }

}

