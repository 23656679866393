/* ------------------------------------- */
/* ----- Header ----- */
/* ------------------------------------- */

.header {
    position: relative;
    height: 100vh;
    min-height: 600px;
    background-color: #293335;
    background-image: url('./src/img/header3.jpg');
    background-size: cover;
    background-position: center;
    text-align: center;

    @media(min-width: 768px) {
        
    }

    &__text-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        max-width: 114rem;
        max-width: 150rem;
        transform: translate(-50%, -50%);
        opacity: .95;
    }

    &__description {

        max-width: 50rem;
        // margin: 0 auto;
        margin: 0 auto;
        font-size: 1.8rem;
        color: #FFFFFF;
        opacity: .7;


        @media (min-width: 768px) {
            width: 70%;
            font-size: 2.5rem;
        }
    }

    &__director {
        position: absolute;
        bottom: 0;
        left: 50%;
        padding-bottom: 1rem;
        transform: translateX(-50%);
        color: $color-white;
        opacity: .7;

        @media(min-width: 768px) {
            font-size: 1.7rem;
        }
    }

}

/* ------------------------------------- */
/* ----- About ----- */
/* ------------------------------------- */

.about {
    background: #EDF4EB;
    color: #000000;
}

.heading-secondary-about {

    font-size: 2.4rem;
    font-weight: 100;
    color: #000000;


    @media(min-width: 768px) {
        font-size: 4.5rem;
    }

}

.intro-text-about {
    color: #000000;
    max-width: 50rem;
    margin: 0 auto;
    font-size: 2.3rem;
    text-align: center;

    @media(min-width: 768px) {
        font-size: 2.4rem;
        max-width: 70rem;
    }
}

/* ------------------------------------- */
/* ----- Projects ----- */
/* ------------------------------------- */

.project {

    background: #293335;
    scroll-snap-type: x mandatory;

    .row {
        margin-top: 3rem;

        @media(min-width: 768px) {
            margin-top: 5rem;
            display: table;
            padding: 3rem 10rem;
        }
    }

    .hack {
        border: none;
        background: transparent;
        box-shadow: none;
    }

    &__item {
        display: block;
        margin: auto;
        max-width: 50rem;
        text-align: center;
        border: 1px solid rgb(233, 232, 232);
        padding: 1rem 1rem;
        background: #EDF4EB;
        box-shadow: 0 .2rem .2rem rgba($color-black, .1);
        border-radius: 3px;
        scroll-snap-align: center;

        &:first-of-type {
            margin: 0 auto 0.2rem auto;
        }

        @media(min-width: 1250px) {
            display: table-cell;
            padding: 0.5rem;
        }

    }

    &__img {
        height: 9rem;
        display: inline-block;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        fill: #555;
    }

    &__description {
        margin: 2rem 0;
        font-size: 1.8rem;

        @media(min-width: 768px) {
            font-size: 1.75rem;
        }
    }

}

/* ------------------------------------- */
/* ----- Footer ----- */
/* ------------------------------------- */

.footer {
    background: #EDF4EB;
    color: #000000;
    padding: 5rem 5rem 0rem 5rem;

    &__social-links {
        list-style: none;
        text-align: center;
        padding-bottom: 6rem;
    }

    &__social-link {
        display: inline-block;
        font-size: 2rem;

        &:not(:last-child) {
            margin-right: 1rem;
        }

        @media(min-width: 768px) {
            font-size: 2.6rem;

            &:not(:last-child) {
                margin-right: 2rem;
            }
        }

        & a {
            color: inherit;
            text-decoration: none;
            transition: all .2s;

            &:hover {
                color: #baeac4;
            }
        }
    }

    &__outro {
        text-align: center;
        max-width: 200rem;
        margin: 0 auto;
        padding: 1rem 0.5rem 1rem 0.5rem;

        @media (min-width: 768px) {
            @include clearfix;

            & p {
                float: left;

                &:not(:last-child) {
                    float: right;
                }
            }
        }
    }

    &__info {
        font-size: 1.8rem;

        @media(min-width: 768px) {
            font-size: 2.3rem;
        }

        a {
            border-bottom: 1px dashed;
            color: inherit;
            text-decoration: none;
            transition: all .2s;

            &:hover {
                color: #baeac4;
            }
        }

    }

    .number {
        color: white;
        margin: 2rem 0 0 0;
        font-size: 1.8rem;
        text-decoration: none;
        @media(min-width: 768px) {
            font-size: 2.3rem;
        }
    }


}