*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    font-size: 62.5%;
}

body {
    font-family: 'Lato', sans-serif;
    font-size: $default-font-size;
    font-weight: 300;
    color: #000000;
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    color: #468954;
}



p {
    line-height: 1.7;
}

section {
    padding: 6rem 2rem;



    @media(min-width: 768px) {
        padding: 10em 0;
    }

}

.intro-text {
    max-width: 50rem;
    margin: 0 auto;
    font-size: 1.8rem;
    text-align: center;
    color: #FFFFFF;

    @media(min-width: 768px) {
        font-size: 2.4rem;
        max-width: 70rem;
    }

}
